const { get, isEmpty, } = require('lodash');
const { startOfDay, } = require('date-fns');

module.exports = {
  fields: () => {
    return {
      date: {
        label: '発生日',
        type: 'date',
        validations: {
          required: v => v != null,
        },
        initialValue: startOfDay(new Date()),
        readOnly: _ => true,
      },
      amount: {
        label: '金額',
        type: 'integer',
        validations: {
          required: v => v != null,
        },
      },
      note: {
        label: '備考',
        type: 'text',
      },
    };
  },
};
