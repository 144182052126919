import React from 'react';
import { pick, uniqBy, } from 'lodash';
import { format as formatDate, addMinutes, } from 'date-fns';

import firebase, { functions } from '../../firebase';
import { fieldDisplayValue } from '../../shared/util';
import { bankFields } from '../../shared/models/tenantSettings';
import BankSettingsFormModal from '../modals/BankSettingsFormModal';
import Page from '../hocs/Page';
import ModalButton from '../ModalButton';
import EditButton from '../EditButton';
import SettingsTabs from '../SettingsTabs';

const { entries } = Object;

export default Page(function SettingsBank (props) {
  const { user, tenant } = props;
  const withEditBatch = (batch, newValues) => {
    if(!!tenant.isDeposit !== newValues.isDeposit) {
      batch.set(tenant.ref, {
        depositSettingLogs: firebase.firestore.FieldValue.arrayUnion({
          savedAt: new Date(), isDeposit: newValues.isDeposit, savedBy: pick(user, ['id', 'email', 'displayName']),
        }),
      }, { merge: true });
    }
  };

  return (
    <div className="settings-bank container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>設定</h4>
        </div>
        <SettingsTabs activeTab="bank" />
        <div className="d-flex justify-content-end mb-3">
          <EditButton color="primary" itemRef={tenant.ref} FormModal={BankSettingsFormModal} withBatch={withEditBatch} />
        </div>
        <div>
          <table className="table table-bordered">
            <tbody className="thead-light">
              {
                entries(bankFields()).map(([fieldName, fieldSetting]) => {
                  const { label } = fieldSetting;
                  return (
                    <tr key={fieldName}>
                      <th style={{ width: 200 }}>
                        {label}
                      </th>
                      <td>
                        {tenant && fieldDisplayValue(tenant[fieldName], fieldSetting)}
                        {fieldName === 'isDeposit' && <ModalButton size="sm" color="link" title="履歴" label={<span className="fas fa-history" />} content={_ => <DepositSettingLogs tenant={tenant} />} />}
                      </td>
                    </tr>
                  );
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});

function DepositSettingLogs (props) {
  const { tenant, } = props;
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>日時</th>
          <th>保存者</th>
          <th>設定</th>
        </tr>
      </thead>
      <tbody>
        {
          (tenant.depositSettingLogs || []).map((depositSettingLog, i) => {
            const { savedAt, savedBy, isDeposit } = depositSettingLog;
            return (
              <tr key={i}>
                <td>
                  {formatDate(savedAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                </td>
                <td>
                  {savedBy.displayName}
                </td>
                <td className="text-right">
                  {isDeposit ? 'YES' : 'NO'}
                </td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
}
