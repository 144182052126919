import React, { useState, } from 'react';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';
import classnames from 'classnames';

import firebase from '../firebase';
import useDocumentSubscription from './hooks/useDocumentSubscription';

const db = firebase.firestore();

export default function EditButton ({ label = '編集', validateOnSubmit = _ => true, itemRef, FormModal, formProps, processValues = _ => _, onFinish = _ => _, withBatch = _ => _, ...extraProps }) {
  const [showsModal, setShowsModal] = useState(false);
  const item = useDocumentSubscription(showsModal && itemRef, [showsModal]);
  const onSubmit = async (values) => {
    if(!(await validateOnSubmit({ id: itemRef.id, ...values }))) return;
    try {
      const batch = db.batch();
      const processedValues = await processValues(values);
      batch.update(itemRef, processedValues);
      withBatch(batch, processedValues);
      await batch.commit();
      await onFinish(processedValues);
      toast.success('保存しました');
      setShowsModal(false);
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Button color="secondary" onClick={_ => setShowsModal(true)} {...extraProps}>
      <span className={classnames('fas fa-edit', { 'mr-1': label })} />
      {label}
      {
        item && (
          <FormModal isOpen={showsModal} values={item} onClickClose={_ => setShowsModal(false)} onSubmit={onSubmit} {...formProps} />
        )
      }
    </Button>
  );
};
