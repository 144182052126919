import React, { Fragment } from 'react';
import { get, keyBy, orderBy, sumBy, } from 'lodash';
import { formatISO, format as formatDate, addMonths, startOfMonth, } from 'date-fns';
import numeral from 'numeral';
import { Link } from 'react-router-dom';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useToggle } from 'react-use';

import { yen, } from '../../util';
import firebase from '../../firebase';
import { paymentSchedules as _paymentSchedules, paymentScheduleFields } from '../../shared/models/tenant';
import { computePaymentFee } from '../../shared/models/sale';
import { firstShippedOrders } from '../../shared/models/order';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';
import Page from '../hocs/Page';
import SalesTabs from '../SalesTabs';

const db = firebase.firestore();
const ordersRef = db.collection('orders');
const { max } = Math;

export default Page(function Sales (props) {
  const { tenant } = props;

  return (
    <div className="sales container-fluid">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>売上管理</h4>
        </div>
        <div className="d-flex justify-content-between align-items-end mb-2">
          <EditButton
          label="早期入金オプション設定"
          itemRef={tenant.ref}
          FormModal={ModelFormModal}
          formProps={{
            title: '早期入金オプション設定',
            renderBodyHeader: _ => (
              <div className="mb-4 alert alert-info">
                当月の15日までに設定すると当月分の売上から適用されます。
                <br />
                16日以降に設定すると翌月分の売上から適用されます。
              </div>
            ),
            fields: {
              paymentSchedule: {
                ...paymentScheduleFields().paymentSchedule,
                hint: (
                  <div>
                    <div>
                      <span className="mr-1">※</span>
                      手数料率は合計出金額に対する手数料率になります。
                    </div>
                    <div className="d-flex">
                      <span className="mr-1">※</span>
                      入金日が土日祝の場合はその前日に入金されます。
                    </div>
                  </div>
                ),
              },
            },
          }}
        />
        </div>
        <div className="mt-4">
          <SalesTable tenant={tenant} />
        </div>
      </div>
    </div>
  );
});

export function SalesTable(props) {
  const { tenant } = props;
  const now = new Date();
  const sales = useCollectionSubscription(tenant.ref.collection('sales'), [tenant.id]);
  const paymentSchedules = useCollectionSubscription(tenant.ref.collection('paymentSchedules'), [tenant.id]);
  const paymentSchedulesById = keyBy(paymentSchedules, 'id');
  const invoices = useCollectionSubscription(tenant.ref.collection('invoices'), [tenant.id]);
  const sortedSales = orderBy(sales, _ => _.targetMonth.toDate(), 'desc');
  const twoMonthsOrders = useCollectionSubscription(ordersRef.where('tenantId', '==', tenant.id).where('createdAt', '>=', startOfMonth(addMonths(now, -1))), [tenant]);
  const filteredOrders = firstShippedOrders(twoMonthsOrders, startOfMonth(now));
  const currentMonthRewards = useCollectionSubscription(tenant.ref.collection('rewards').where('createdAt', '>=', startOfMonth(now)), [tenant]);
  const currentMonthSale = {
    isCurrentMonth: true,
    id: formatDate(now, 'yyyy-MM'),
    amount: sumBy(filteredOrders.map(_ => _.items || []).flat(), _ => _.netPrice * _.quantity),
    count: filteredOrders.length,
    rewardsAmount: sumBy(currentMonthRewards, 'amount'),
    rewardsCount: currentMonthRewards.length,
    targetMonth: { toDate: _ => now },
  };

  return (
    <table className="table">
      <thead className="thead-light text-center">
        <tr className="text-nowrap">
          <th>売上月</th>
          <th>受取金額</th>
          <th>売上件数</th>
          <th>報酬金額</th>
          <th>報酬件数</th>
          <th>小計</th>
          <th>請求金額</th>
          <th>合計金額</th>
          <th>手数料</th>
          <th>入金額</th>
          <th>入金日</th>
        </tr>
      </thead>
      <tbody>
        {
          [currentMonthSale, ...sortedSales].map((sale) => {
            const { isCurrentMonth, id, amount, count, rewardsAmount = 0, rewardsCount = 0, targetMonth, shouldDeposit = false, } = sale;
            const paymentScheduleValue = get(paymentSchedulesById, [id, 'paymentSchedule'], (isCurrentMonth ? (tenant.paymentSchedule || 'end') : 'end'));
            const paymentSchedule = _paymentSchedules[paymentScheduleValue];
            const subtotalAmount = amount + rewardsAmount;
            const monthInvoices = invoices.filter(_ => formatDate(_.date.toDate(), 'yyyyMM') === formatDate(targetMonth.toDate(), 'yyyyMM'));
            const invoicesAmount = sale.invoicesAmount ?? sumBy(monthInvoices, 'amount');
            const monthTotalAmount = subtotalAmount - invoicesAmount;
            const totalAmount = sale.totalAmount ?? monthTotalAmount;
            const paymentScheduleFee = Math.floor(totalAmount * paymentSchedule.feeRate / 100);
            const paymentFee = sale.paymentFee != null ? sale.paymentFee : computePaymentFee(totalAmount - paymentScheduleFee);
            const totalFee = paymentScheduleFee + paymentFee;
            const conclusiveAmount = totalAmount <= 0 ? totalAmount : max(0, totalAmount - totalFee);

            return (
              <tr key={id}>
                <td>
                  {formatDate(targetMonth.toDate(), 'yyyy/MM')}
                  {shouldDeposit && <span className="ml-1 badge badge-info">デポジット</span>}
                </td>
                <td className="text-right">
                  {yen(amount)}
                </td>
                <td className="text-right">
                  {numeral(count).format('0,0')}
                </td>
                <td className="text-right">
                  {yen(rewardsAmount)}
                </td>
                <td className="text-right">
                  {numeral(rewardsCount).format('0,0')}
                </td>
                <td className="text-right font-weight-bold">
                  {yen(subtotalAmount)}
                </td>
                <td className="text-right">
                  <InvoicesAmountDisplay invoices={monthInvoices} />
                </td>
                <td className="text-right font-weight-bold">
                  {yen(monthTotalAmount)}
                </td>
                <td className="text-right">
                  {!shouldDeposit && !isCurrentMonth && yen(totalFee)}
                </td>
                <td className="text-right font-weight-bold">
                  {!shouldDeposit && !isCurrentMonth && yen(conclusiveAmount)}
                </td>
                <td>
                  {!shouldDeposit && !isCurrentMonth && formatDate(addMonths(targetMonth.toDate(), 1), 'yyyy年MM月')}{!shouldDeposit && !isCurrentMonth && paymentSchedule.dateLabel}
                </td>
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
}

function InvoicesAmountDisplay(props) {
  const { invoices } = props;
  const [showsModal, toggleModal] = useToggle(false);
  const onClick = (e) => {
    e.preventDefault();
    toggleModal(true);
  };

  return (
    <Fragment>
      <Link onClick={onClick}>
        {yen(sumBy(invoices, 'amount'))}
      </Link>
      {
        showsModal && (
          <Modal isOpen>
            <ModalHeader>
              請求内容確認
            </ModalHeader>
            <ModalBody>
              <table className="table">
                <thead className="thead-light text-center">
                  <tr className="text-nowrap">
                    <th>発生日</th>
                    <th>金額</th>
                    <th>備考</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    invoices.map((invoice) => {
                      const { id, ref, date, amount, note, } = invoice;

                      return (
                        <tr key={id}>
                          <td>
                            {formatDate(date.toDate(), 'yyyy/MM/dd')}
                          </td>
                          <td className="text-right">
                            {numeral(amount).format()}
                          </td>
                          <td style={{ whiteSpace: 'pre-line' }}>
                            {note}
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </ModalBody>
            <ModalFooter>
              <Button className="cancel" color="secondary" onClick={toggleModal.bind(null, false)}>閉じる</Button>
            </ModalFooter>
          </Modal>
        )
      }
    </Fragment>
  );
}
